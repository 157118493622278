@import url(https://fonts.googleapis.com/css?family=Poppins&display=swap);
@import url(https://fonts.googleapis.com/css?family=Alegreya+Sans+SC&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#main_container{
  font-family: 'Poppins', sans-serif;
  min-height: 100vh !important;
}
.page_header{
  color:#233f78;
  font-weight:400;
}
.small_label{
  color:#233f78;
  font-size: 13px;
  font-weight:600;
}
.xs_label{
  color:#233f78;
  font-size: 12px;
  font-weight:600;
}
input{
  color:#233f78;
}
.form-control{
  color:#233f78;
}
.header_text{
  color:#233f78;
  font-size:12vh;
  margin-top: 40vh;
}
.text-orange{
  color:#f37421;
}
.text-blue {
  color:#233f78;
}
.bg-orange{
  background-color:#f37421;
}
.bg-blue {
  background-color:#233f78;
}

.bg-indigo {
  background: #4B0082;
}
.text-indigo {
  color: #4B0082;
}
.api_loader_container, .loader_container{
  position:fixed;
  z-index:99999;
  top:0px;
  left:0px;
  right:0px;
  bottom:0px;
  text-align: center;
  background:rgba(255,255,255,0.4);
}
.loader_image {
  margin-top:30vh;
}

@media (max-width:768px )
{
  .header_text{
    color:#233f78;
    font-size:8vh;
    margin-top: 40vh;
  }
}

/* todo: delete file if not needed */
.message_display {
    position: fixed;
    top: 8vh;
    right: 10px;
    width:auto;
    height: auto;
    padding: 4px;
    z-index: 2;
}
.login_modal_container {
    align-items: center;
    justify-content: center;
    background: transparent;
}
.login_icon {
    border-radius: 50%;
    border: 4px dotted #ffff;
    margin:10px;
}
.login_container {
    position: fixed !important;
    top: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 12;
    width: auto;
    text-align: center;
    padding:20px;
    color: #ffff;
    min-width: 300px;
    box-shadow: -2px 0px 6px rgba(0,0,0,0.8);
    /* border-left: 1px solid #ffff; */
    background: -webkit-gradient(linear, left top, left bottom, from(#44A1A0), to(#172A3A)) !important;
    background: -webkit-linear-gradient(top, #44A1A0, #172A3A) !important;
    background: linear-gradient(to bottom, #44A1A0, #172A3A) !important;
}
.login_container h6{
    color: #ffff;
}
.login_modal_container .form-control, .login_modal_container .form-control:focus {
    background: transparent;
    color: white;
    outline: none;
    border: none;
    border:0.1px solid whitesmoke;
    box-shadow: none;
    border-radius: 0px;
    max-width: 260px !important;
}
.login_modal_container .form-control::-webkit-input-placeholder {
    color: whitesmoke;
}
.login_modal_container .form-control::-moz-placeholder {
    color: whitesmoke;
}
.login_modal_container .form-control:-ms-input-placeholder {
    color: whitesmoke;
}
.login_modal_container .form-control::-ms-input-placeholder {
    color: whitesmoke;
}
.login_modal_container .form-control::placeholder {
    color: whitesmoke;
}
.login_modal_container .input-group-text {
    background: #172A3A;
    color: #ffff;
    border-radius: 0px;
    width: 40px;
    justify-content: center;
}

/* Side callback container */
.main_callback_container {
    align-items: center;
    justify-content: center;
    background: transparent;
}
.callback_icon {
    border-radius: 50%;
    border: 4px dotted #ffff;
    margin:10px;
}
.callback_container {
    position: fixed !important;
    top: 0px;
    left: 0px;
    bottom: 0px;
    z-index: 10;
    width: auto;
    text-align: center;
    /* padding:20px; */
    color: #ffff;
    min-width: 300px;
    box-shadow: 2px 0px 6px rgba(0,0,0,0.8);
    /* border-left: 1px solid #ffff; */
    background: -webkit-gradient(linear, left top, left bottom, from(#44A1A0), to(#172A3A)) !important;
    background: -webkit-linear-gradient(top, #44A1A0, #172A3A) !important;
    background: linear-gradient(to bottom, #44A1A0, #172A3A) !important;
}
.callback_container h6{
    color: #ffff;
}
.main_callback_container .form-control, .main_callback_container .form-control:focus {
    color: #172a3a;
    outline: none;
    border: none;
    border:0.1px solid whitesmoke;
    box-shadow: none;
    border-radius: 0px;
    max-width: 260px !important;
}

input:-webkit-autofill, input:-webkit-autofill-strong-password {
    background-color: white !important;
}

.menu_close_btn {
    /* float: right; */
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer !important;
}
.login_content_section {
    background: transparent;
    color: white;
    border-right: 1px solid #44A1A0;
}
.login_btn {
    background-color: #172A3A;
    color: #ffff !important;
    border:1px solid white;
    padding: 6px 20px;
    box-shadow: none;
    font-size: 14px;
    -webkit-transition: background-color 1s, color 1s, box-shadow 1s;
    transition: background-color 1s, color 1s, box-shadow 1s;
}
.login_btn:hover {
    background-color: #44A1A0;
    box-shadow: 2px 2px 4px black;
}
.abroad_btn {
    padding: 5px 15px;
    color: #ffff;
    border: none;
    box-shadow: none;
    background-color: #172A3A;
    -webkit-transition: background-color 1s, box-shadow 1s, color 1s, border 1s;
    transition: background-color 1s, box-shadow 1s, color 1s, border 1s;
}
.abroad_btn:hover, .abroad_btn:focus {
    background-color: #44A1A0;
    outline: none !important;
    /* box-shadow: 2px 2px 4px black; */
}
.abroad_btn.user_link {
    width:90%;
    margin: 10px auto;
    display: block;
    padding: 8px;
    border:1px dotted #ffff;
    -webkit-transition: background-color 1s;
    transition: background-color 1s;
}
.abroad_btn.user_link:hover, .abroad_btn.user_link_active {
    color: #ffff;
    background-color: #44A1A0;
}
.breadcrumb {
    background : transparent !important;
    padding:2px 5px !important;
}
.breadcrumb-item a {
    color:#233f78 !important;
}
.breadcrumb-item a:hover, .breadcrumb-item {
    font-size:13px !important;
    color: #800080 !important;
}
.breadcrumb > li + li:before {
    content: '\3E' !important;
}
.up_arrow {
    position: fixed;
    border:2px solid #ffff;
    bottom: 30px;
    right:30px;
    color: #ffff;
    border-radius: 50%;
    background: #44a1a0;
    padding:10px;
    z-index:5;
}
.main_header {
    font-size: 36px;
    color: #f37421;
    margin-bottom: 5px;
    font-family: 'Alegreya Sans SC', sans-serif;
}
.sub_header {
    font-size: 18px;
    color: #233f78;
}
.hori_line {
    height: 2px;
    margin-top:0px;
    width: 100px;
    background: -webkit-gradient(linear, right top, left top, from(#172a3a), to(#44a1a0));
    background: -webkit-linear-gradient(right, #172a3a, #44a1a0);
    background: linear-gradient(to left, #172a3a, #44a1a0);
}
.popular_section {
    background: white;
}
.popular_section .card {
    box-shadow:4px 4px 10px 2px lightgrey;
    color: #ffff;
    -webkit-transition: margin-top 1s, box-shadow 1s;
    transition: margin-top 1s, box-shadow 1s;
    text-shadow: 0px 0px 10px rgba(0,0,0,0.8);
}
.popular_section .card:hover {
    margin-top: -10px;
    box-shadow:6px 6px 10px 2px lightgrey;
}
.fav_icon {
    position: absolute;
    top:10px;
    right: 10px;
    border-radius:50%;
    padding:8px;
    z-index: 5;
    font-size: 22px !important;
    cursor: pointer;
    border: 2px solid#fff;
    background: rgba(0,0,0,0.1);
    color: rgb(255,0,0.8);
}
.col_details {
    position: absolute;
    bottom: 10px;
    left: 10px;
    z-index: 5;
    text-align: left;
    font-weight: 600;
}
.popular_icon {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 5;
    background: #f37421;
    border-radius:20px;
    border:2px solid #ffff;
    padding:5px 10px;
    color: #ffff;
}
.interested_container {
    background: -webkit-gradient(linear, left top, right top, from(#44A1A0), to(#172A3A));
    background: -webkit-linear-gradient(left, #44A1A0, #172A3A);
    background: linear-gradient(to right, #44A1A0, #172A3A);
    color: #ffff;
}
.interested_container .abroad_btn {
    border:1px dotted white;
}
.interested_container .main_header {
    color: #ffff;
}
.bg_overlay {
    position: absolute;
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: 0px;
    background: rgba(0,0,0,0.8);
    /* background: linear-gradient(to right,  #f37421, #f37421, #233f78, #233f78);  */
    opacity:0.2;
}
.card .bg_overlay {
    opacity: 0.4;
}
.check_label {
    margin-left:5px;
    color: #233f78;
    font-size: 14px;
}
/* todo: delete file if not needed */
.filter_header {
    padding:10px;
    border: none;
    color: #800080;
    font-size: 20px;
    text-transform: uppercase;
    text-align: left;
    border-bottom:1px solid lightgray;
    cursor: pointer;
}
.filters_card input {
    cursor: pointer;
}
.filter_sub_header {
    padding:6px;
    font-size: 14px;
    color: #800080;
    text-transform: uppercase;
    border-bottom:1px solid lightgray;
}
.filter_selector {
    padding:6px;
    border:1px solid lightgrey;
    /* box-shadow: 0px 2px 4px lightgray; */
    border-radius:5px;
    margin-bottom: 10px;
}
.filter_header i {
    float: right;
    padding-top:5px;
    font-size: 14px;
}
.clear_btn {
    border: 1px solid #B23A48;
    color: #B23A48;
    padding: 4px 8px;
    font-size: 13px;
    background-color: transparent;
    -webkit-transition: background-color 1s, color 0.5s, border 0.5s;
    transition: background-color 1s, color 0.5s, border 0.5s;
}
.clear_btn:hover {
    border: 1px solid #9E0031;
    background-color: #9E0031;
    color: #ffff;
}
.filter_div {
    padding:6px;
    text-align: left;
    border-bottom:1px solid lightgray;
}
.filters_card {
    border: 1px solid lightgrey;
    box-shadow: 0px 2px 6px lightgray;
    /* position: sticky;
    top:10vh; */
}
.filter_div select::before, .filter_div select.form-control {
    border-radius:0px !important;
    color: #233f78;
}
.filter_div .form-control {
    height: 40px;
    color: #233f78;
}
img.filter_loader {
    margin-top:6vh;
    min-height: 300px;
}
.no_results {
    color: #f37421;
}

.filter_div input.form-control, .filter_div input.form-control:focus {
    background: transparent !important;
    color:#233f78 !important;
    border:1px solid lightgray;
    border-radius: 20px !important;
    outline:#233f78 !important;
    outline-offset: 0px;
    box-shadow: none;
    padding-left: 30px;
    -webkit-transition: box-shadow 1s;
    transition: box-shadow 1s;
}
.filter_div input.form-control:hover, .filter_div input.form-control:focus {
    box-shadow: 0px 0px 6px 1px lightgray;
}
.filter_div .input-group>.custom-select:not(:last-child), .filter_div .input-group>.form-control:not(:last-child) {
    border-radius: 20px !important;
}
.filter_div .input-group-text {
    position: absolute;
    top: 4px;
    left: 3px;
    bottom: 4px;
    z-index: 4;
    background: transparent;
    border: none;
    color: #233f78;
}
.popular_div, .mci_div, .fmge_div {
    padding:3px 6px;
    font-size: 13px;
    margin: 5px;
    font-weight: 500;
    color: white;
    border: 1px solid rgba(0,0,0,0.8);
}
.popular_div {
    background: #233f78;
}
.mci_div {
    background: #f37421;
}
.fmge_div {
    background: #17a2b8;
}

.autocomplete_card {
    position: absolute;
    top: 40px;
    padding:10px;
    background: #ffff;
    color: #233f78;
}
ul.pagination {
    float:right;
}
ul.pagination li {
    padding:8px 13px;
    background:#ffff;
    font-weight: 500;
    border:1px solid rgba(0,0,0,0.2);
}
ul.pagination a {
    color:#233f78;
}
ul.pagination li.active {
    background:#233f78;
    box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.6);
}
ul.pagination li.active a {
    color:#ffff !important;
}
ul.pagination li.disabled {
    opacity:0.5;
}
.small_text {
    font-size:12px;
    font-weight: 400;
}
label.small_text {
    margin-bottom:3px;
}
.text_blue {
    color: #233f78 !important;
}
@media screen and (max-width:768px) {
    
}

#home_navbar {
    position: fixed;
    top: 0px;
    right: 0px;
    left: 0px;
    padding: 2px 20px;
    background: #fff;
    box-shadow: none;
    border-bottom: 1px solid lightgrey;
    z-index:10;
    box-shadow: 0px 2px 4px lightgrey;
}
#main_header_navbar ul {
    padding: 10px !important;  
}
#main_header_navbar .nav-link {
    color: #233f78;
    -webkit-transition: color 0.5s;
    transition: color 0.5s;
    cursor: pointer;
}
#main_header_navbar .nav-link:hover, .link_active {
    color:#f37421!important;
}
#home_navbar .form-inline .input-group {
    border-radius:20px;
}
.header_search input.form-control, .header_search input.form-control:focus {
    background: transparent !important;
    color:#44a1a0 !important;
    border:1px solid lightgray;
    border-radius: 20px !important;
    outline:#44a1a0 !important;
    outline-offset: 0px;
    box-shadow: none;
    padding-left: 35px;
    -webkit-transition: box-shadow 1s;
    transition: box-shadow 1s;
}
.header_search input.form-control:hover, .header_search input.form-control:focus {
    box-shadow: 0px 2px 4px lightgray;
}
.header_search .input-group>.custom-select:not(:last-child), .header_search .input-group>.form-control:not(:last-child) {
    border-radius: 30px !important;
}
.header_search .input-group-text {
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    z-index:4;
    background: transparent;
    border: none;
    color: #233f78;
    justify-content: center;
}

.header_search input::-webkit-input-placeholder {
    color: darkgrey;
}

.header_search input::-moz-placeholder {
    color: darkgrey;
}

.header_search input:-ms-input-placeholder {
    color: darkgrey;
}

.header_search input::-ms-input-placeholder {
    color: darkgrey;
}

.header_search input::placeholder {
    color: darkgrey;
}

.countries_dropdown_content {
    position: fixed;
    top: 50px;
    left: 0px;
    right: 0px;
    background-color: #ffff;
    padding: 20px;
    z-index: 10;
    border-top: 1px solid whitesmoke;
    box-shadow: 0px 2px 10px lightgray;
}
.countries_dropdown_content .country_link {
    padding: 0px;
    margin: 2px;
    text-decoration: none;
    color: #233f78;
    -webkit-transition: margin-left 0.6s, color 0.4s;
    transition: margin-left 0.6s, color 0.4s;
    cursor: pointer;
}
.countries_dropdown_content .country_link:hover, .country_link:focus {
    margin-left: 10px;
    color: #f37421 !important;
}

.autocomplete_results {
    position: absolute;
    top:40px;
    left: 3%;
    right: 3%;
    width: 94%;
    z-index: 7;
    font-size: 11px;
    background:#ffff;
    box-shadow: 0px 4px 10px lightgrey; 
    border: 1px solid lightgrey;
}
.no-suggestions {
    color: #44a1a0;
    padding: 0.5rem;
}
.suggestions {
    border: 1px solid #0000;
    border-top-width: 0;
    list-style: none;
    max-height: 240px !important;
    overflow-y: scroll;
    padding: 2px;
    margin-bottom: 0px !important;
}
.suggestions li {
    padding: 0.5rem;
}
.suggestion-active, .suggestions li:hover {
    background-color: #44a1a0;
    color: #ffff;
    cursor: pointer;
    font-weight: 700;
}
.suggestions li:not(:last-of-type) {
    border-bottom: 1px solid #fff;
}
/* todo: delete file if not needed */
.home_bg_img {
    height: 100vh !important;
    width: 100%;
}
.home_background {
    /* min-height: 100vh; */
    /* position: relative; */
    /* overflow: auto; */
    /* background: #44a1a0; */
    /* background-image: linear-gradient(to right, #44a1a0, #172a3a); */
}
.explore_container {
    background-image: -webkit-gradient(linear, left top, right top, from(#44a1a0), to(#172a3a));
    background-image: -webkit-linear-gradient(left, #44a1a0, #172a3a);
    background-image: linear-gradient(to right, #44a1a0, #172a3a);
}
.home_background div:first-child {
    /* background: #44a1a0; */
}
.home_banner_content {
    /* position: absolute; */
    /* top: 15%;
    left:10%; */
    /* right:10%; */
    margin:auto;
    /* position: relative; */
    z-index : 6;
    /* background: #233f78; */
}
/* .home_background .bg_overlay {
    opacity: 0.5;
    background: #44a1a0;
} */
.home_banner_content .search_header {
    /* color: #2EC4B6 !important; */
    color: #44a1a0 !important;
    /* font-size: 4vh; */
    /* font-weight: 600; */
    /* text-shadow: 2px 2px 2px #172a3a; */
}
.home_banner_content h6 {
    color: #233f78 !important;
    font-weight: 400;
    text-shadow: 0px 0px 1px rgba(0,0,0,0.8);
}
.home_search_group .form-control, .home_search_group .form-control:focus{
    width:100%;
    padding-left: 20px !important;
    padding-right: 10% !important;
    margin:0px !important;
    border-radius: 40px !important;
    font-size : 20px !important;
    color: #44a1a0 !important;
    height: 55px;
    border:1.5px solid lightgrey;
    box-shadow: 0px 2px 6px lightgrey;
    -webkit-transition: box-shadow 1s;
    transition: box-shadow 1s;
}
.home_search_group .form-control:hover, .home_search_group .form-control:focus {
    box-shadow: 0px 4px 16px lightgrey;
}
.home_search_group .input-group>.custom-select:not(:last-child), .home_search_group .input-group>.form-control:not(:last-child) {
    border-radius: 40px !important;
}
.home_search_group .input-group-text {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 4;
    font-size: 4vh;
    padding-left:3vh;
    padding-right:3vh; 
    background: #172a3a;
    color: #fff;
    border: 1px solid #ffff;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    cursor: pointer;
    -webkit-transition: background 1s;
    transition: background 1s;
}
.home_search_group .input-group-text:hover {
    background:#44a1a0 !important;
}
.home_search_group .form-control::-webkit-input-placeholder {
    color: lightgrey !important;
}
.home_search_group .form-control::-moz-placeholder {
    color: lightgrey !important;
}
.home_search_group .form-control:-ms-input-placeholder {
    color: lightgrey !important;
}
.home_search_group .form-control::-ms-input-placeholder {
    color: lightgrey !important;
}
.home_search_group .form-control::placeholder {
    color: lightgrey !important;
}
.home_country_select {
    min-width: 200px;
    height: 50px;
    border-radius: 30px;
    border: 1px solid lightgrey;
    color: #233f78;
    box-shadow: 0px 2px 6px lightgrey;
}
.home_service_container {
    /* background-image: linear-gradient(to right, #44a1a0, #172a3a); */
}
.home_service_container .header {
    color: #ffff;
}
.home_service_container .card {
    background-color: #ffff;
    /* border:1px solid lightgrey; */
    color:#172a3a;
    /* box-shadow: 2px 4px 10px lightgrey; */
    border-radius: 10px;
    cursor: pointer;
    box-shadow: none;
    -webkit-transition: margin-top 1s, background-color 1s, color 1s, box-shadow 1s;
    transition: margin-top 1s, background-color 1s, color 1s, box-shadow 1s;
}
.home_service_container .card:hover {
    color: #44a1a0;
    margin-top: -8px;
    box-shadow: 0px 0px 20px 2px rgba(0,0,0,0.6);
}
.home_service_container .card p {
    font-size: 13px;
    color: #233f78;
}
.home_service_container .card span{
    margin-top:10px;
    font-size: 24px;
}
.down_arrow {
    position: absolute;
    border:1px solid #ffff;
    bottom: 20px;
    left:47.7%;
    border-radius: 50%;
    background: black;
    padding:10px 12px;
    z-index:5;
    -webkit-animation: flash 5s infinite;
            animation: flash 5s infinite;
}

.home_search_group .autocomplete_results {
    position: absolute;
    top:58px;
    left: 3%;
    right: 3%;
    width: 94%;
    z-index: 11 !important;
    margin: auto;
    text-align: left;
    font-size: 13px;
    background:white;
    border: 1px solid darkgrey;
    /* box-shadow: 0px 0px 10px rgba(0,0,0,0.6); */
}
.home_search_group .no-suggestions {
    color: #172a3a;
    padding: 0.5rem;
}
.home_search_group .suggestions {
    border: 1px solid #0000;
    border-top-width: 0;
    list-style: none;
    max-height: 240px !important;
    overflow-y: scroll;
    padding: 10px;
    margin-bottom: 0px !important;
}
.home_search_group .suggestions li {
    padding: 0.5rem;
}
.home_search_group .suggestion-active, .home_search_group .suggestions li:hover {
    background-color: #44a1a0;
    color: #ffff;
    cursor: pointer;
    font-weight: 700;
}
.home_search_group .suggestions li:not(:last-of-type) {
    border-bottom: 1px solid whitesmoke;
}
.mci_fmge_group {
    position: absolute;
    top: 10px;
    left: 10px;
}
.mci_fmge_group .fmge_div, .mci_fmge_group .mci_div {
    font-size: 14px;
    font-weight: 600;
}
#popular_colleges_section {
    background-color: whitesmoke;
}

@media screen and (max-width:768px) {
    .home_banner_content h1 {
        font-size: 24px;
    }
    .home_banner_content h4 {
        font-size: 16px;
    }
    .home_search_group {
        margin-top: 20px !important;
    }
    .home_search_group .input-group-text {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
    .home_service_container {
        margin-top: 20px !important;
    }
}
.about_us_container {
    padding-top: 70px;
}

.about_us_container .header {
    color: #233f78;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 14px;
}
.about_us_container .para {
    font-size: 14px;
    color: #172a3a;
    text-align: justify;
}
.description_card {
    padding: 3vh 12vh;
}
.description_card div {
    padding: 20px 30px;
}
.description_card .service_header {
    text-align: center;
    color: #800080;
    text-transform: uppercase;
}
.about_us_container .description_card:nth-child(odd) {
    background: white;
}
.about_us_container .description_card:nth-child(even) {
    background: whitesmoke;
}

@media screen and (max-width:768px) {
    .description_card {
        padding: 20px !important;
    }
    .description_card div {
        padding: 10px 15px !important;
    }
}
.pricing_container {
    margin: 30px auto;
}
.pricing_container .header {
    color: #233f78;
}
.pricing_container .pricing_package {
    background: #ffff;
    box-shadow: 4px 4px 10px lightgray;
}
.pricing_container .package_header {
    padding: 20px 5px;
    color: #ffff;
    background: #44a1a0;
}
.pricing_container .package_body {
    padding: 15px 15px 15px 30px;
    background: #ffff;
    color: rgba(0,0,0,0.6);
    text-align: left;
}
.pricing_container .popular_banner {
    position: absolute;
    top: -30px;
    right: -6px;
    z-index: 2;
    height: 66px;
}
.pricing_container .offer_banner {
    height: 50px;
}
.pricing_container .benefit_true i {
    color: #2EC4B6;
}
.pricing_container .benefit_false i {
    color: #bd2426;
}
.pricing_container .benefit_false {
    text-decoration: line-through;
}
.pricing_container .abroad_btn {
    box-shadow: none;
    width: 50%;
    padding: 10px;
}
.pricing_container .actual_price {
    text-decoration: line-through;
    color: #ffff;
}


.checkout_container {
    margin: 30px auto;
}
.checkout_container .header {
    color: #233f78;
}
.checkout_container .pricing_package {
    background: #ffff;
    box-shadow: 4px 4px 10px lightgray;
}
.checkout_container .checkout_package {
    color: #233f78;
}
.checkout_container .package_header {
    padding: 20px 5px;
    color: #ffff;
    background: #44a1a0;
}
.checkout_container .package_body {
    padding: 15px 15px 15px 30px;
    background: #ffff;
    color: rgba(0,0,0,0.6);
    text-align: left;
}
.checkout_container .check_icon {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 2;
    height: 77px;
    background:white;
    border-radius: 50%;
    padding: 8px;
}
.checkout_container .benefit_true i {
    color: #2EC4B6;
}
.checkout_container .benefit_false i {
    color: #bd2426;
}
.checkout_container .benefit_false {
    text-decoration: line-through;
}
.checkout_container .abroad_btn {
    box-shadow: none;
    width: 100%;
    padding: 10px;
}
.checkout_container .cancel_btn {
    background: #44a1a0;
}
.checkout_container .header {
    color: #2EC4B6;
}
.checkout_container .actual_price {
    text-decoration: line-through;
    color: #ffff;
}
.checkout_container .offer_banner {
    height: 50px;
}


.register_container {
    background: -webkit-gradient(linear, left top, left bottom, from(#44A1A0), to(#172A3A)) !important;
    background: -webkit-linear-gradient(top, #44A1A0, #172A3A) !important;
    background: linear-gradient(to bottom, #44A1A0, #172A3A) !important;
    align-items: center;
    justify-content: center;
    background: transparent;
    min-height:100vh;
}
.register_container h3 {
    color: #ffff;
    font-weight: 600;
}
.register_container [type=radio] {
    opacity: 0;
    height:0px;
}
.register_container [type=radio] + img {
  cursor: pointer;
  border-radius: 50%;
  opacity: 0.6;
  box-shadow: none;
  border: 5px solid transparent;
  -webkit-transition: box-shadow 1s, border 1s, opacity 1s;
  transition: box-shadow 1s, border 1s, opacity 1s;
}
.register_container [type=radio]:checked + img {
  box-shadow: 0px 2px 10px rgba(0,0,0,0.8);
  border: 5px solid whitesmoke;
  opacity: 1;
}
.register_container .terms_check a {
    color: whitesmoke;
}
.register_container .terms_check a:hover {
    color: #2EC4B6;
}
.register_details {
    color: #ffff;
}
.register_container .form-control, .register_container .form-control:focus {
    background: transparent;
    color: white;
    outline: none;
    border: none;
    border:0.1px solid whitesmoke;
    box-shadow: none;
    border-radius: 0px;
}
.register_container .form-control::-webkit-input-placeholder {
    color: whitesmoke;
}
.register_container .form-control::-moz-placeholder {
    color: whitesmoke;
}
.register_container .form-control:-ms-input-placeholder {
    color: whitesmoke;
}
.register_container .form-control::-ms-input-placeholder {
    color: whitesmoke;
}
.register_container .form-control::placeholder {
    color: whitesmoke;
}
.register_container .input-group-text {
    background: #172A3A;
    width: 40px;
    justify-content: center;
    color: #ffff;
    border-radius: 0px;
}
#contact_container .contact_form {
    box-shadow: 2px 2px 10px rgba(0,0,0,0.6);
    border:1px solid whitesmoke;
}
#contact_container div.contact {
    color: #ffff;
    font-weight: 800;
}
#contact_container .bg_overlay {
    opacity: 0.4;
    background: #172a3a;
}
#contact_container hr{
    background: white;
}
#contact_container .social {
    padding: 0px;
}
#contact_container .social li {
    display: inline;
    margin:auto 10px;
}
#contact_container .social a {
    font-size: 24px;
    color: #233f78;
    -webkit-transition: color 1s;
    transition: color 1s;
}
#contact_container .social a:hover {
    color: #2EC4B6;
}
#contact_container .header {
    color: #233f78;
}
#contact_container img {
    height: 60px;
    margin-bottom: 10px;
}
#contact_container .sub_header {
    color: #44a1a0;
    font-size: 18px;
    font-weight: 600;
}
#contact_container p.para{
    font-size: 14px;
    color: #233f78;
}
#contact_container .branch_div {
    padding: 20px;
}
#branch_offices p.para {
    font-size: 13px;
}
/* todo: delete file if not needed */
.interested_side_container {
    background: -webkit-gradient(linear, left top, left bottom, from(#44A1A0), to(#172A3A));
    background: -webkit-linear-gradient(top, #44A1A0, #172A3A);
    background: linear-gradient(to bottom, #44A1A0, #172A3A);
    padding: 20px;
    color: #ffff;
}
.interested_side_container .form-control {
    color: #172A3A;
}
.interested_side_container .abroad_btn {
    border: 2px dotted #ffff;
}
/* todo: delete file if not needed */
.country_card img{
    height: 200px;
    width: 100%;
}
.col_details h3, .col_details span {
    color: #ffff;
}

.filter_results .college_card {
    border: 1px solid lightgrey;
    border-radius: 0px;
    text-align:left;
    box-shadow: 0px 2px 4px lightgrey;
    margin-bottom: 15px;
    -webkit-transition: box-shadow 1s;
    transition: box-shadow 1s;
}
.filter_results .college_card:hover {
    box-shadow: 0px 4px 10px 2px lightgrey;
}
.filter_results .college_card h5 {
    color: #233f78;
    margin-bottom: 2px;
}
.filter_results .college_card .small_text {
    margin: 0px;
    color: #172a3a;
}
.filter_results .college_card img {
    height: 100px;
    width: 100px;
}
.filter_results .college_card .accr_name {
    font-size: 12px;
}
.filter_results .fav_icon {
    padding: 0px;
    top: 0px;
    right: 0px;
    background: none;
    font-size: 24px !important;
}
.filter_results .abroad_btn {
    border-radius: 0px;
    font-size: 13px;
    padding: 3px 6px;
    box-shadow: none;
    border: 1px solid black;
}
.filter_results .course_container {
    margin-top: 10px;
    border-top: 1px solid lightgray;
    padding: 10px;
    color: #233f78;
}
.filter_results .course_container p {
    font-size: 13px;
    display: flex;
    margin-bottom: 2px;
}
.filter_results .course_container p i {
    margin: 3px 5px 0px 0px;
}
/* todo: delete file if not needed */
.college_container .banner_div {
    height: 33vh;
    padding:0px;
}
.college_container .bg_overlay {
    opacity: 0.8;
}
.college_container img.col_banner {
    width:100%;
    height: 100%;
}
.college_container img.col_logo {
    height: 150px;
    width: 150px;
}
.college_details {
    position: absolute;
    top: 3vh;
    right: 2vh;
    left: 2vh;
    z-index: 5;
    /* background: rgba(0,0,0,0.6); */
    color: #ffff;
    font-weight: bolder;
}
#college_navbar {
    position: -webkit-sticky;
    position: sticky;
    top: 62px;
    right: 0px;
    left: 0px;
    background:#ffff;
    box-shadow: none;
    border-bottom: 1px solid lightgrey;
    z-index:5;
    box-shadow: 0px 2px 4px lightgrey;
}
#college_navbar .navbar_btn {
    position: static;
}
#college_details_navbar span.nav-link{
    color: #233f78;
    cursor: pointer;
    padding: 10px auto;
    border-bottom: 2px solid transparent;
}
#college_details_navbar span.nav-link:hover, #college_details_navbar span.nav-link.active{
    color:#800080 !important;
    font-weight: bolder !important;
}
.college_details_card {
    box-shadow: 0px 0px 10px lightgray;
    margin:2px -6px;
}
.college_details_card .details_div {
    border-bottom: 1px solid lightgrey;
    padding:10px 20px;
}
.college_details_card .details_div h5{
    font-weight: 500;
    padding: 10px 0px 0px;
    color: #800080;
    text-align: left;
}
.college_details_card .hori_line {
    margin-left:0px;
    width: 60px;
}
.college_details_card .details_div p{
    color: rgba(0,0,0,0.7);
    text-align: justify;
    font-size: 14px;
}
.college_container .fav_icon {
    font-size: 26px !important;
    top:0px;
    right: 20px;
}
#courses_div p {
    font-size: 13px;
    display: flex;
    margin-bottom: 2px;
}
#courses_div p i {
    margin: 3px 5px 0px 0px;
}
#courses_div a, #courses_div h6 {
    color: #233f78;
}
#courses_div .course_details:nth-child(even) {
    background: white;
}
#courses_div .course_details:nth-child(odd) {
    background: whitesmoke;
}
#courses_div .abroad_btn {
    font-size: 13px;
    width: 100%;
    box-shadow: none;
}
#courses_div .table th {
    color: #172a3a !important;
    font-weight:600;
}
#courses_div .table {
    color: #233f78 !important;
}
#facilities_div img {
    height: 55px;
    width: 55px;
}
#facilities_div h6 {
    margin-top: 10px;
    color: #233f78;
    font-weight: 500;
    text-align: center;
}
#contact_div p{
    color: #233f78;
}
#gallery_div img {
    height: 500px;
    width: 100%;
    padding: 4px;
    border:1px solid black;
    border-radius: 4px;
    object-fit: contain !important;
}
.image-gallery-content, .image-gallery-slide {
    background: rgba(0,0,0,0.9) !important;
}
.image-gallery-thumbnail {
    opacity: 0.6 !important;
    width: 20% !important;
}
.image-gallery-thumbnail.active {
    background: none !important;
    opacity: 1 !important;
}
.image-gallery-image {
    height: 100% !important;
    object-fit: cover !important;
}
.image-gallery-thumbnail-inner, .image-gallery-thumbnail-inner img {
    height: 150px !important;
    object-fit: cover !important;
}

@media screen and (max-width:768px) {
    .college_container img.col_logo {
        height: 66px;
        width: 66px;
    }
    .college_container .banner_div {
        height: 50vh;
    }
    .college_container .fav_icon {
        top:-60px;
    }
    .college_container h2 {
        font-size: 22px;
        margin-top:10px;
        text-align: center;
    }
    .college_details {
        /* display: none !important; */
    }
    #gallery_div img {
        height: 350px;
    }
    .image-gallery-thumbnail-inner, .image-gallery-thumbnail-inner img {
        height: 100px !important;
    }
}
.compare_container {
    margin-top: 40px;
    min-height: 40vh;
}
.compare_container .header {
    color: #233f78;
}
.compare_container .autocomplete_results {
    background:#ffff;
    border: 1px solid lightgray;
    position: absolute;
    top: 45px;
    box-shadow: none;
    box-shadow: 0px 0px 6px lightgray;
}
.compare_container input[type=search].form-control {
    color: #233f78;
    padding-right: 40px;
    -webkit-transition: box-shadow 0.5s;
    transition: box-shadow 0.5s;
    height: 44px;
}
.compare_container input[type=search].form-control:focus {
    border: 1px solid lightgray;
    outline: none;
    box-shadow: 0px 2px 6px lightgray;
}
.compare_container .input-group-text {
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    padding: 12px;
    background: transparent;
}

.compare_container .sub_header {
    color: #ffff;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.5;
}
.compare_container p {
    color: #233f78;
    font-size: 15px;
    margin: 4px;
}
.compare_container .compare_cat ul {
    list-style-type: none;
    padding: 4px 10px;
}
.compare_container .course_header {
    padding: 8px 4px;
    color: #44a1a0;
    font-weight: 600;
}
.compare_cat ul > li {
    line-height: 2;
}
.compare_cat ul > li .course_check {
    margin-right: 6px;
    margin-top: 2px;
}
.compare_container .small_text {
    color: #172a3a;
    text-decoration: underline;
}
.compare_container .compare_cat {
    padding: 4px;
    color: #233f78;
    border: 1px solid lightgrey;
}
.compare_container .compare_cat i {
    font-size: 18px;
}
.compare_container .college_header {
    position: -webkit-sticky;
    position: sticky;
    top: 70px;
    z-index:2;
    padding: 10px;
    min-height: 80px;
    box-shadow: 0px 0px 4px black;
    background: #44a1a0;
    border: 1px solid white;
}
.compare_container .compare_cat a {
    color: #ffff;
}
.compare_container .course_check {
    cursor: pointer;
    margin-top: 14px !important;
}
.compare_container .course_check i.fa {
    font-size: 15px;
}
.compare_container .course_check[aria-expanded=true] i.fa-plus {
    display: none;
}
.compare_container .course_check[aria-expanded=false] i.fa-minus {
    display: none;
}
.compare_container .fav_icon {
    position: absolute;
    top: -20px;
    right: -14px;
    font-size: 24px !important;
    background: #ffff;
    padding: 6px;
}
.edit_profile_container {
    margin:30px auto;
}
.edit_profile_container .header {
    color: #233f78;
}
.edit_profile_container .sub_header {
    color: #44a1a0;
    font-size: 22px;
    font-weight: 500;
}
.edit_profile_container .para {
    color: #233f78;
}
.edit_profile_container .change_password, .edit_profile_container .edit_profile {
    box-shadow: 0px 4px 10px lightgray;
    padding: 20px;
}
.edit_profile_container .form-control {
    color: #233f78;
}
.edit_profile_container .small_text {
    color: #172a3a;
    float: left;
}
.edit_profile_container .abroad_btn {
    padding: 8px 20px;
}
.change_password .show_hide_pwd {
    font-size: 30px;
    color: #233f78;
    padding: 4px 10px;
    /* color: #ffff; */
}
.footer_container {
    background-color: #172a3a;
    color: whitesmoke;
    font-size: 13px;
    padding: 50px 30px 10px;
}
.footer_container hr {
   background:grey;
}
.footer_container .connect_with p {
    font-size: 16px;
}
.footer_container ul.branches li, .footer_container ul.social li {
    list-style: none;
    margin-right: 20px;
    display: inline;
}
.footer_container ul.navigate li {
    list-style: none;
}
.footer_container ul.navigate li a, .footer_container ul.branches li a {
    color: #ffff;
    -webkit-transition: color 0.5s, margin-left 0.5s;
    transition: color 0.5s, margin-left 0.5s;
}
.footer_container ul.navigate li a:hover, .footer_container .link_active {
    color: #2EC4B6 !important;
    margin-left: 6px;
}
.footer_container ul.branches li a:hover {
    color: #2EC4B6 !important;
}
.footer_container ul.navigate li i {
    font-size: 10px;
}
.footer_container .copyright span {
    font-size: 14px;
    color: #f37421;
}
.footer_container .social_icon {
    font-size: 26px;
    color: #ffff;
    -webkit-transition: color 0.4s;
    transition: color 0.4s;
}
.footer_container .social_icon:hover {
    color: #2EC4B6;
}

