.register_container {
    background: linear-gradient(to bottom, #44A1A0, #172A3A) !important;
    align-items: center;
    justify-content: center;
    background: transparent;
    min-height:100vh;
}
.register_container h3 {
    color: #ffff;
    font-weight: 600;
}
.register_container [type=radio] {
    opacity: 0;
    height:0px;
}
.register_container [type=radio] + img {
  cursor: pointer;
  border-radius: 50%;
  opacity: 0.6;
  box-shadow: none;
  border: 5px solid transparent;
  transition: box-shadow 1s, border 1s, opacity 1s;
}
.register_container [type=radio]:checked + img {
  box-shadow: 0px 2px 10px rgba(0,0,0,0.8);
  border: 5px solid whitesmoke;
  opacity: 1;
}
.register_container .terms_check a {
    color: whitesmoke;
}
.register_container .terms_check a:hover {
    color: #2EC4B6;
}
.register_details {
    color: #ffff;
}
.register_container .form-control, .register_container .form-control:focus {
    background: transparent;
    color: white;
    outline: none;
    border: none;
    border:0.1px solid whitesmoke;
    box-shadow: none;
    border-radius: 0px;
}
.register_container .form-control::placeholder {
    color: whitesmoke;
}
.register_container .input-group-text {
    background: #172A3A;
    width: 40px;
    justify-content: center;
    color: #ffff;
    border-radius: 0px;
}