.edit_profile_container {
    margin:30px auto;
}
.edit_profile_container .header {
    color: #233f78;
}
.edit_profile_container .sub_header {
    color: #44a1a0;
    font-size: 22px;
    font-weight: 500;
}
.edit_profile_container .para {
    color: #233f78;
}
.edit_profile_container .change_password, .edit_profile_container .edit_profile {
    box-shadow: 0px 4px 10px lightgray;
    padding: 20px;
}
.edit_profile_container .form-control {
    color: #233f78;
}
.edit_profile_container .small_text {
    color: #172a3a;
    float: left;
}
.edit_profile_container .abroad_btn {
    padding: 8px 20px;
}
.change_password .show_hide_pwd {
    font-size: 30px;
    color: #233f78;
    padding: 4px 10px;
    /* color: #ffff; */
}