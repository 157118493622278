.about_us_container {
    padding-top: 70px;
}

.about_us_container .header {
    color: #233f78;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 14px;
}
.about_us_container .para {
    font-size: 14px;
    color: #172a3a;
    text-align: justify;
}
.description_card {
    padding: 3vh 12vh;
}
.description_card div {
    padding: 20px 30px;
}
.description_card .service_header {
    text-align: center;
    color: #800080;
    text-transform: uppercase;
}
.about_us_container .description_card:nth-child(odd) {
    background: white;
}
.about_us_container .description_card:nth-child(even) {
    background: whitesmoke;
}

@media screen and (max-width:768px) {
    .description_card {
        padding: 20px !important;
    }
    .description_card div {
        padding: 10px 15px !important;
    }
}