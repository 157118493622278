.footer_container {
    background-color: #172a3a;
    color: whitesmoke;
    font-size: 13px;
    padding: 50px 30px 10px;
}
.footer_container hr {
   background:grey;
}
.footer_container .connect_with p {
    font-size: 16px;
}
.footer_container ul.branches li, .footer_container ul.social li {
    list-style: none;
    margin-right: 20px;
    display: inline;
}
.footer_container ul.navigate li {
    list-style: none;
}
.footer_container ul.navigate li a, .footer_container ul.branches li a {
    color: #ffff;
    transition: color 0.5s, margin-left 0.5s;
}
.footer_container ul.navigate li a:hover, .footer_container .link_active {
    color: #2EC4B6 !important;
    margin-left: 6px;
}
.footer_container ul.branches li a:hover {
    color: #2EC4B6 !important;
}
.footer_container ul.navigate li i {
    font-size: 10px;
}
.footer_container .copyright span {
    font-size: 14px;
    color: #f37421;
}
.footer_container .social_icon {
    font-size: 26px;
    color: #ffff;
    transition: color 0.4s;
}
.footer_container .social_icon:hover {
    color: #2EC4B6;
}
