/* todo: delete file if not needed */
.college_container .banner_div {
    height: 33vh;
    padding:0px;
}
.college_container .bg_overlay {
    opacity: 0.8;
}
.college_container img.col_banner {
    width:100%;
    height: 100%;
}
.college_container img.col_logo {
    height: 150px;
    width: 150px;
}
.college_details {
    position: absolute;
    top: 3vh;
    right: 2vh;
    left: 2vh;
    z-index: 5;
    /* background: rgba(0,0,0,0.6); */
    color: #ffff;
    font-weight: bolder;
}
#college_navbar {
    position: sticky;
    top: 62px;
    right: 0px;
    left: 0px;
    background:#ffff;
    box-shadow: none;
    border-bottom: 1px solid lightgrey;
    z-index:5;
    box-shadow: 0px 2px 4px lightgrey;
}
#college_navbar .navbar_btn {
    position: static;
}
#college_details_navbar span.nav-link{
    color: #233f78;
    cursor: pointer;
    padding: 10px auto;
    border-bottom: 2px solid transparent;
}
#college_details_navbar span.nav-link:hover, #college_details_navbar span.nav-link.active{
    color:#800080 !important;
    font-weight: bolder !important;
}
.college_details_card {
    box-shadow: 0px 0px 10px lightgray;
    margin:2px -6px;
}
.college_details_card .details_div {
    border-bottom: 1px solid lightgrey;
    padding:10px 20px;
}
.college_details_card .details_div h5{
    font-weight: 500;
    padding: 10px 0px 0px;
    color: #800080;
    text-align: left;
}
.college_details_card .hori_line {
    margin-left:0px;
    width: 60px;
}
.college_details_card .details_div p{
    color: rgba(0,0,0,0.7);
    text-align: justify;
    font-size: 14px;
}
.college_container .fav_icon {
    font-size: 26px !important;
    top:0px;
    right: 20px;
}
#courses_div p {
    font-size: 13px;
    display: flex;
    margin-bottom: 2px;
}
#courses_div p i {
    margin: 3px 5px 0px 0px;
}
#courses_div a, #courses_div h6 {
    color: #233f78;
}
#courses_div .course_details:nth-child(even) {
    background: white;
}
#courses_div .course_details:nth-child(odd) {
    background: whitesmoke;
}
#courses_div .abroad_btn {
    font-size: 13px;
    width: 100%;
    box-shadow: none;
}
#courses_div .table th {
    color: #172a3a !important;
    font-weight:600;
}
#courses_div .table {
    color: #233f78 !important;
}
#facilities_div img {
    height: 55px;
    width: 55px;
}
#facilities_div h6 {
    margin-top: 10px;
    color: #233f78;
    font-weight: 500;
    text-align: center;
}
#contact_div p{
    color: #233f78;
}
#gallery_div img {
    height: 500px;
    width: 100%;
    padding: 4px;
    border:1px solid black;
    border-radius: 4px;
    object-fit: contain !important;
}
.image-gallery-content, .image-gallery-slide {
    background: rgba(0,0,0,0.9) !important;
}
.image-gallery-thumbnail {
    opacity: 0.6 !important;
    width: 20% !important;
}
.image-gallery-thumbnail.active {
    background: none !important;
    opacity: 1 !important;
}
.image-gallery-image {
    height: 100% !important;
    object-fit: cover !important;
}
.image-gallery-thumbnail-inner, .image-gallery-thumbnail-inner img {
    height: 150px !important;
    object-fit: cover !important;
}

@media screen and (max-width:768px) {
    .college_container img.col_logo {
        height: 66px;
        width: 66px;
    }
    .college_container .banner_div {
        height: 50vh;
    }
    .college_container .fav_icon {
        top:-60px;
    }
    .college_container h2 {
        font-size: 22px;
        margin-top:10px;
        text-align: center;
    }
    .college_details {
        /* display: none !important; */
    }
    #gallery_div img {
        height: 350px;
    }
    .image-gallery-thumbnail-inner, .image-gallery-thumbnail-inner img {
        height: 100px !important;
    }
}