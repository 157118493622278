@import url('https://fonts.googleapis.com/css?family=Alegreya+Sans+SC&display=swap');

/* todo: delete file if not needed */
.home_bg_img {
    height: 100vh !important;
    width: 100%;
}
.home_background {
    /* min-height: 100vh; */
    /* position: relative; */
    /* overflow: auto; */
    /* background: #44a1a0; */
    /* background-image: linear-gradient(to right, #44a1a0, #172a3a); */
}
.explore_container {
    background-image: linear-gradient(to right, #44a1a0, #172a3a);
}
.home_background div:first-child {
    /* background: #44a1a0; */
}
.home_banner_content {
    /* position: absolute; */
    /* top: 15%;
    left:10%; */
    /* right:10%; */
    margin:auto;
    /* position: relative; */
    z-index : 6;
    /* background: #233f78; */
}
/* .home_background .bg_overlay {
    opacity: 0.5;
    background: #44a1a0;
} */
.home_banner_content .search_header {
    /* color: #2EC4B6 !important; */
    color: #44a1a0 !important;
    /* font-size: 4vh; */
    /* font-weight: 600; */
    /* text-shadow: 2px 2px 2px #172a3a; */
}
.home_banner_content h6 {
    color: #233f78 !important;
    font-weight: 400;
    text-shadow: 0px 0px 1px rgba(0,0,0,0.8);
}
.home_search_group .form-control, .home_search_group .form-control:focus{
    width:100%;
    padding-left: 20px !important;
    padding-right: 10% !important;
    margin:0px !important;
    border-radius: 40px !important;
    font-size : 20px !important;
    color: #44a1a0 !important;
    height: 55px;
    border:1.5px solid lightgrey;
    box-shadow: 0px 2px 6px lightgrey;
    transition: box-shadow 1s;
}
.home_search_group .form-control:hover, .home_search_group .form-control:focus {
    box-shadow: 0px 4px 16px lightgrey;
}
.home_search_group .input-group>.custom-select:not(:last-child), .home_search_group .input-group>.form-control:not(:last-child) {
    border-radius: 40px !important;
}
.home_search_group .input-group-text {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 4;
    font-size: 4vh;
    padding-left:3vh;
    padding-right:3vh; 
    background: #172a3a;
    color: #fff;
    border: 1px solid #ffff;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    cursor: pointer;
    transition: background 1s;
}
.home_search_group .input-group-text:hover {
    background:#44a1a0 !important;
}
.home_search_group .form-control::placeholder {
    color: lightgrey !important;
}
.home_country_select {
    min-width: 200px;
    height: 50px;
    border-radius: 30px;
    border: 1px solid lightgrey;
    color: #233f78;
    box-shadow: 0px 2px 6px lightgrey;
}
.home_service_container {
    /* background-image: linear-gradient(to right, #44a1a0, #172a3a); */
}
.home_service_container .header {
    color: #ffff;
}
.home_service_container .card {
    background-color: #ffff;
    /* border:1px solid lightgrey; */
    color:#172a3a;
    /* box-shadow: 2px 4px 10px lightgrey; */
    border-radius: 10px;
    cursor: pointer;
    box-shadow: none;
    transition: margin-top 1s, background-color 1s, color 1s, box-shadow 1s;
}
.home_service_container .card:hover {
    color: #44a1a0;
    margin-top: -8px;
    box-shadow: 0px 0px 20px 2px rgba(0,0,0,0.6);
}
.home_service_container .card p {
    font-size: 13px;
    color: #233f78;
}
.home_service_container .card span{
    margin-top:10px;
    font-size: 24px;
}
.down_arrow {
    position: absolute;
    border:1px solid #ffff;
    bottom: 20px;
    left:47.7%;
    border-radius: 50%;
    background: black;
    padding:10px 12px;
    z-index:5;
    animation: flash 5s infinite;
}

.home_search_group .autocomplete_results {
    position: absolute;
    top:58px;
    left: 3%;
    right: 3%;
    width: 94%;
    z-index: 11 !important;
    margin: auto;
    text-align: left;
    font-size: 13px;
    background:white;
    border: 1px solid darkgrey;
    /* box-shadow: 0px 0px 10px rgba(0,0,0,0.6); */
}
.home_search_group .no-suggestions {
    color: #172a3a;
    padding: 0.5rem;
}
.home_search_group .suggestions {
    border: 1px solid #0000;
    border-top-width: 0;
    list-style: none;
    max-height: 240px !important;
    overflow-y: scroll;
    padding: 10px;
    margin-bottom: 0px !important;
}
.home_search_group .suggestions li {
    padding: 0.5rem;
}
.home_search_group .suggestion-active, .home_search_group .suggestions li:hover {
    background-color: #44a1a0;
    color: #ffff;
    cursor: pointer;
    font-weight: 700;
}
.home_search_group .suggestions li:not(:last-of-type) {
    border-bottom: 1px solid whitesmoke;
}
.mci_fmge_group {
    position: absolute;
    top: 10px;
    left: 10px;
}
.mci_fmge_group .fmge_div, .mci_fmge_group .mci_div {
    font-size: 14px;
    font-weight: 600;
}
#popular_colleges_section {
    background-color: whitesmoke;
}

@media screen and (max-width:768px) {
    .home_banner_content h1 {
        font-size: 24px;
    }
    .home_banner_content h4 {
        font-size: 16px;
    }
    .home_search_group {
        margin-top: 20px !important;
    }
    .home_search_group .input-group-text {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
    .home_service_container {
        margin-top: 20px !important;
    }
}