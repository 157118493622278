@import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');

#main_container{
  font-family: 'Poppins', sans-serif;
  min-height: 100vh !important;
}
.page_header{
  color:#233f78;
  font-weight:400;
}
.small_label{
  color:#233f78;
  font-size: 13px;
  font-weight:600;
}
.xs_label{
  color:#233f78;
  font-size: 12px;
  font-weight:600;
}
input{
  color:#233f78;
}
.form-control{
  color:#233f78;
}
.header_text{
  color:#233f78;
  font-size:12vh;
  margin-top: 40vh;
}
.text-orange{
  color:#f37421;
}
.text-blue {
  color:#233f78;
}
.bg-orange{
  background-color:#f37421;
}
.bg-blue {
  background-color:#233f78;
}

.bg-indigo {
  background: #4B0082;
}
.text-indigo {
  color: #4B0082;
}
.api_loader_container, .loader_container{
  position:fixed;
  z-index:99999;
  top:0px;
  left:0px;
  right:0px;
  bottom:0px;
  text-align: center;
  background:rgba(255,255,255,0.4);
}
.loader_image {
  margin-top:30vh;
}

@media (max-width:768px )
{
  .header_text{
    color:#233f78;
    font-size:8vh;
    margin-top: 40vh;
  }
}
